/* this file is transformed by vux-loader */
/* eslint-disable */
import Vue from 'vue';
import listItem from '@/components/common/ListItem';
Vue.component(listItem.name, listItem);
export default {
  data() {
    return {
      msgItem: {}
    };
  },
  methods: {
    updateStatus() {
      if (this.msgItem.isRead == 'yes') return;
      var url = _baseUrl + "/oa/info/infInbox/updateStatus.do";
      this.$ajax({
        url: url,
        method: 'POST',
        params: {
          pkId: this.msgItem.pkId
        }
      }).then(response => {}).catch(error => {
        console.log(error);
      });
    }
  },
  created() {
    this.msgItem = this.$route.params.msgItem;
    this.updateStatus();
  }
};